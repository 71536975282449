import React, { useEffect, useState } from 'react'
import { Carousel, Skeleton } from 'antd'

import axiosRequest from '../../../../../hooks/useAxiosRequest'
import { limit } from '../../../../../util/functions/limit'
import { CompanyCard } from '../../../../ui/cards'
import { redirectExploreProducts } from '../../../../../util/functions'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'

const LatestSuppliers = () => {
  const [loading, setLoading] = useState(true)
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    setLoading(true)
    axiosRequest('/api/v1/suppliers/all?sortNewest=true', null, 'get', true)
      .then((res) => {
        if (res.data) {
          setSuppliers(limit(res.data.data.data, 8))
        }
      })
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <ErrorBoundary>
          <Carousel
            dots={false}
            className="row"
            infinite
            autoplay={true}
            slidesToShow={3}
            swipeToSlide
            centerMode
            draggable={true}
            variableWidth={true}
            adaptiveHeight={true}
          >
            {suppliers.length > 0 &&
              suppliers.map((supplier) => {
                if (supplier.get_images.length > 0) {
                  return (
                    <div className="col-12 col-md-6 col-lg-3" key={supplier.id}>
                      <ErrorBoundary>
                        <CompanyCard
                          company={supplier}
                          classNamePrfx="home"
                          onImageClick={() =>
                            redirectExploreProducts(
                              `supplier=${supplier.id}&title=${supplier.name}'s Products`,
                            )
                          }
                        />
                      </ErrorBoundary>
                    </div>
                  )
                }
              })}
          </Carousel>
        </ErrorBoundary>
      )}
    </div>
  )
}

export default LatestSuppliers
