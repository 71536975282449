import React from 'react'
import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'
import { Carousel } from 'antd'

import styles from './carousel.module.scss'

import placeholder from '../../../../assets/placeholder/company logo placeholder.png'

const Brands = ({ data }) => {
  return (
    <MarketplaceCard title="Brands">
      <div className={styles.carousel}>
        <Carousel
          dots={false}
          arrows
          draggable
          touchMove
          infinite={false}
          slidesToScroll={1}
          slidesToShow={1}
          nextArrow={
            <div>
              <svg
                style={{
                  position: 'absolute',
                  left: '100%',
                }}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.6"
                  cx="15"
                  cy="15"
                  r="14.5"
                  fill="white"
                  stroke="#EDEDED"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3334 10L12.1584 11.175L15.975 15L12.1584 18.825L13.3334 20L18.3334 15L13.3334 10Z"
                  fill="#808080"
                />
              </svg>
            </div>
          }
          prevArrow={
            <div>
              <svg
                style={{
                  position: 'absolute',
                  right: '100%',
                }}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.6"
                  cx="15"
                  cy="15"
                  r="14.5"
                  transform="rotate(-180 15 15)"
                  fill="white"
                  stroke="#EDEDED"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6666 20L17.8416 18.825L14.025 15L17.8416 11.175L16.6666 10L11.6666 15L16.6666 20Z"
                  fill="#808080"
                />
              </svg>
            </div>
          }
        >
          {data.map((brand) => (
            <div key={brand.id} className={styles.item}>
              <div className={styles.itemWrapper}>
                <img src={brand.logo} alt={brand.name} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </MarketplaceCard>
  )
}

export default Brands
